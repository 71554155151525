/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Custom Import
@import "./theme/theme.scss";
@import "./theme/colors.scss";



// TODO - move to partial
.chip-outline {
    ion-label {
        font-size: 0.9rem !important;
        line-height: 1.2;
    }
}

.action-sheet-button {
    &.action-important {
        font-weight: bold;
    }
}

.md {
    .action-sheet-title {
        background-color: rgb(19, 16, 16);
        color: white;
    }
    .action-sheet-button {
        &.action-sheet-cancel {
            border-top: 1px solid rgba(0,0,0,0.2);
        }
    }
}

.alert-wrapper {
    max-width: unset !important;

    > .alert-radio-group {
        max-width: 90vw !important;
        width: 350px !important;
    }
}

// Override default ionic component height to be a little bit taller & show more options.
.alert-checkbox-group.sc-ion-alert-md, .alert-radio-group.sc-ion-alert-md {
    max-height: 300px;
}

.repairerActionSheet {
    .alert-radio-group.sc-ion-alert-md {
        width: 480px !important;
        max-width: 90vw !important;
    }
}

.assign-repairer-selector-alert {
    .alert-checkbox-group.sc-ion-alert-md, .alert-radio-group.sc-ion-alert-md,
    .alert-checkbox-group.sc-ion-alert-ios, .alert-radio-group.sc-ion-alert-ios {
      max-height: 580px;
    }
    .alert-wrapper > .alert-radio-group {
      max-width: 90vw !important;
      width: 400px !important;
    }
  }

.alert-select {
    border: 1px solid currentColor !important;
    margin-right: auto !important;
}

// Defect list iew (HomePAGE)
.c-driver-item {
    width: 100%;
    //height: 90px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @media screen and (max-width: 420px) {
       flex-direction: column;
       justify-content: center;
       align-items: flex-start;
    }
    &--side {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        @media screen and (max-width: 420px) {
            width: 100%;
         }
        &__end {
            justify-content: flex-end;
        }
        &--stacked {
            flex-direction: column;
            align-items: end;
        }
    }
}

.c-driver-image {
    border-radius: 50%;
    width: 3em;
    height: 3em;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid;
    position: relative

    ion-icon {
        font-size: 1.5em;
    }

}
.c-driver-details {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    flex-direction: column;
    margin-left: 15px;

    &--edit {
        margin-left: auto;
    }
    &--repairer {
        font-weight: bold;
        font-size: 12px;
    }
    &--alert {
        font-weight: bold;
        color: red;
        margin: 0;
    }

    &--name {
        font-family: 'Open Sans', sans-serif;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        p {
            margin-right: 5px;
        }
    }
    &--number {
        display: inline-block;
        padding: 0px 5px;
        border-bottom: 1px solid;
        opacity: 0.5;
    }
    &--license {
        font-size: 13px;
        opacity: 0.9;
        font-family: 'Open Sans Light', sans-serif;
    }
    &--categories {
        // display: flex;
        // flex-wrap: wrap;
        width: 100%;
        p {
            margin-right: 5px;
        }
    }
    &--notes {
        margin-left: auto;
    }
}

.c-driver-actions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    height: 100%;

    icon-input {
        margin-right: 10px;
    }

    &--more {
        font-size: 30px;
        flex: 0 0 40px;
        cursor: pointer;
    }
   
}

.c-message {
    margin: 1rem;
}
.c-message {

    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .mat-icon {
        margin-right: 6px;
        margin-bottom: 6px;
    }
    h3, &__heading {
        margin: 3px 0;
        font-size: 1.1rem;
    }
    &__text {
        margin: 3px 0;
    }
    &--error {
        h3, &__heading {
            color:  var(--ion-color-error);
        }
        &__text {
            color:  var(--ion-color-error);
        }
        color:  var(--ion-color-error);
        .mat-icon {
            color:  var(--ion-color-error);
        }
        background-color: #ffd3d3;

        .mat-stroked-button {
            border-color:  var(--ion-color-error);
            color:  var(--ion-color-error);
        }
    } 
    &--success {
        h3, &__heading {
            color: var(--ion-color-success);// var(--ion-color-success);
        }
        &__text {
            color:  var(--ion-color-success);
        }
        color:  var(--ion-color-success);
        .mat-icon {
            color:  var(--ion-color-success);
        }
        background-color: #cff6ca;
    } 
    &--info {
        h3, &__heading {
            color: rgb(38, 86, 154);
        }
        &__text {
            color: rgb(38, 86, 154);
        }
        .mat-icon {
            color: rgb(38, 86, 154);
        }
        background-color: #def4fe;
    } 

    &--flec-dir-column {
        flex-direction: column;
    }
}