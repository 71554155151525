@function get-color($name) {
    @return var(--color-#{$name});
}

@function color($name) {
    @return map-get($color-table, $name);
}

// @include dark-mode {} if you want to theme dark...
@mixin dark-mode {
    @media (prefers-color-scheme: dark) { @content; }
}

// @include tablet {} to change styling for these devices.
@mixin tablet {
    @media (min-width: 600px) { @content; }
}

* {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

@font-face {
    font-family: 'Open Sans';
    src:  url('/assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Open Sans Light';
    src:  url('/assets/fonts/OpenSans-Light.ttf');
}

@font-face {
    font-family: 'Open Sans Bold';
    src:  url('/assets/fonts/OpenSans-Bold.ttf');
}