// Define all basic colours in here.
:root {
    --color-coral: #f15c74;
    --color-off-black: #1c1818;
    --color-brownish: #353131;
    --color-grey: #e8e8e8;
    --color-white: #ffffff;
    --color-black: #000000;
}

// Map a key to one of the basic colours.
$color-table: (
    "background": get-color("white"),
    "background-alt": get-color("off-black"),
    "background-mid": get-color("grey"),
    "text": get-color("black"),
    "text-alt": get-color("white"),

    "header-background": get-color("off-black"),
    "header-menu": get-color("brownish"),
    "header-text": get-color("white"),
);
